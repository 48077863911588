.container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.tca_container {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	padding: 100px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.title {
	margin-left: auto;
	margin-right: auto;
}

.spacing {
	line-height: 30px;
}

.notFound_404 {
	color: rgb(223, 43, 43);
	font-size: 68px;
	margin-bottom: 0;
	margin-top: 0;

	&__title {
		margin-bottom: 0;
		margin-top: 0;
	}
}


.iframe {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 0;
}