$select-color: rgb(68, 68, 68);
$selected-color: rgb(14, 14, 14);
$select-background: #dfe9e8;
$select-width: 100%;
$select-height: 40px;

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}
.selectStyled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $select-background;
  padding: 8px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: $select-color transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
  }
  &:hover {
    background-color: darken($select-background, 2);
  }
  &:active,
  &.active {
    background-color: darken($select-background, 5);
    &:after {
      top: 9px;
      border-color: transparent transparent $select-color transparent;
    }
  }
}

.selectOptions {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: darken($select-background, 5);

  &_listItem {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid darken($select-background, 10);
    &:hover {
      color: $select-background;
      background: $select-color;
    }

    &__active {
      color: $select-background;
      background: $selected-color;
    }
  }
}

.form {
  &_group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 25px;

    &_center {
      align-items: center !important;
    }

    @media screen and (max-width: 700px) {
      align-items: center;
    }
  }

  &_group_center &_input {
    text-align: center !important;
  }
  &_label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
  &_input {
    box-sizing: border-box;
    width: 90%;
    max-width: 400px;
    border: 1px solid #818181;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 18px;
    color: #434343;
    @media screen and (max-width: 700px) {
      text-align: center;
    }
    &:focus {
      outline: none;
      border: 1px solid #353535;
    }
  }
  &_button {
    background: #00aeff;
    padding: 10px 25px;
    font-size: 17px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #0084c2;
    }

    &:disabled {
      cursor: progress;
      background-color: #0085c29f;
    }
  }
  &_message {
    &__error {
      color: red;
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin-top: 5px;
    }
    &__success {
      color: green;
      display: block;
    }
  }
}
