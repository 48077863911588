.authMain {
  background: #f1f3f9;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.authContainer {
  display: flex;
  width: 90%;
  max-width: 1200px;
  margin: 30px 0;
  min-height: 95vh;
  @media screen and (max-width: 700px) {
    width: 70%;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }

  &_nightMode &_rightSide {
    background-color: #1a1a1a;
  }

  &_nightMode .inputContainer_label,
  &_nightMode &_rightSide__header,
  &_nightMode &_link,
  &_nightMode &_linkText,
  &_nightMode .inputContainer_smallBtn {
    color: white !important;
  }

  &_nightMode .inputContainer_input {
    background-color: #262626 !important;
    border: 1px solid #818181 !important;
    color: white !important;
  }

  &_leftSide {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 700px) {
      margin-bottom: 20px;
    }
  }

  &_image {
    width: 75%;
  }

  &_rightSide {
    box-sizing: border-box;
    flex: 0.5;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px #00000015;
    background-color: white;
    border-radius: 20px;
    padding: 50px 10px;
    padding-left: 60px;
    justify-content: center;

    @media screen and (max-width: 800px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 700px) {
      flex: 1;
      padding-left: 40px;
    }

    @media screen and (max-width: 600px) {
      align-items: center;
      padding: 50px 20px;
    }

    & form {
      width: 100%;
    }

    &__header {
      color: #172b4d;
      font-size: 30px;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 40px;
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }

  &_submitBtn {
    color: white;
    background-image: url("../../assets/images/buttonBg.png");
    background-position: 0% 95%;
    background-size: 200%;
    border: none;
    min-width: 150px;
    padding: 15px 25px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &_linkText {
    font-size: 15px;
    margin-top: 25px;
  }

  &_link {
    color: #768bb2;
    font-size: 14px;
    font-weight: 500;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: 700px) {
    align-items: center;
  }

  &_label {
    margin-bottom: 6px;
    color: #434343;
    font-size: 15px;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    border: 1px solid #818181;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 18px;
    color: #434343;

    &__forgotPassword {
      text-align: center;
      margin-bottom: 20px;
    }
    &:focus {
      outline: none;
      border: 1px solid #353535;
    }
  }

  &_smallBtn {
    color: #768bb2;
    margin: 0;
    margin-top: 8px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.form {
  &_message {
    &__error {
      color: red;
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin-top: 5px;
      p {
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
    &__success {
      color: green;
      display: block;
    }
  }
}
